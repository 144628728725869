import { useState } from "react";

export default function DetailedVoteComponent(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="w-full mb-5 mx-6">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="flex items-center">
          <span className={`transform transition-transform ${isOpen ? 'rotate-90' : ''}`}>
            ➤
          </span>
          <span className="ml-2">Gedetailleerde stemgeschiedenis</span>
        </div>
      </div>
      {isOpen && (
        <div className="p-4 mt-2 bg-gray-100 rounded-lg w-auto mr-12">
          {props.votingData ? (
            props.votingData.map((vote, index) =>
              vote.id === props.id ? (
                <table className="table-auto w-full text-left" key={index}>
                  <tbody>
                    {vote.data.votes.concat(vote.data.manualvotes).map((voting, idx, arr) => (
                      <tr
                        key={idx}
                        className={`py-4 ${idx < arr.length - 1 ? 'border-b border-gray-300' : ''}`}
                      >
                        <td className="w-1/2 text-left">
                          {voting.user ? `${voting.user.firstname} ${voting.user.lastname}` : "Handmatig toegevoegd"}
                        </td>
                        <td className="w-1/4 text-center">
                          {new Date(voting.created_on).toLocaleTimeString("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                        <td className="w-1/4 text-right">
                          {voting.response === "yes"
                            ? "👍"
                            : voting.response === "no"
                            ? "👎"
                            : "🤷‍♂️"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null
            )
          ) : (
            <p>Geen stemmen</p>
          )}
        </div>
      )}
    </div>
  );
}
