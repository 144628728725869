import { useEffect } from "react"
import { Link } from "react-router-dom"
import { BuildingOffice2Icon, CalendarIcon, QuestionMarkCircleIcon, UserGroupIcon } from "@heroicons/react/20/solid";

export default function AdminHomeView() {
    const actions = [
        {
            title: 'Gebruikers',
            description: 'Beheer de gebruikers van Assemblo.',
            href: 'users',
            icon: UserGroupIcon,
            iconForeground: 'text-red-700',
            iconBackground: 'bg-red-50',
        },
        {
            title: 'Gebouwen',
            description: 'Beheer de gebouwen van Assemblo.',
            href: 'buildings',
            icon: BuildingOffice2Icon,
            iconForeground: 'text-purple-700',
            iconBackground: 'bg-purple-50',
        },
        {
            title: 'Meetings',
            description: 'Beheer de meetings van Assemblo.',
            href: 'meetings',
            icon: CalendarIcon,
            iconForeground: 'text-blue-700',
            iconBackground: 'bg-blue-50',
        },
        {
            title: 'Hulp',
            description: 'Vragen of problemen? Klik hier voor hulp.',
            href: '+32472714637',
            tel: true,
            icon: QuestionMarkCircleIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-yellow-50',
        }
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        document.title = "Assemblo - Admin - Dashboard"
    });

    return (
        <main className="flex-1">
            <div className="py-6">
                <div className="flex mx-auto px-4 sm:px-6 md:px-8">
                    <div>
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
                        </div>
                        <div>
                            <p>Klik door naar de verschillende onderdelen binnen Assemblo.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 w-3/4 mx-auto mt-6">
                {actions.map((action, actionIdx) => (
                    <div
                        key={action.title}
                        className={classNames(
                            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                            actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                        )}
                    >
                        <div>
                            <span
                                className={classNames(
                                    action.iconBackground,
                                    action.iconForeground,
                                    'rounded-lg inline-flex p-3 ring-4 ring-white'
                                )}
                            >
                                <action.icon className="h-6 w-6" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-lg font-medium">
                                {action.tel ?
                                    <a href={`tel:${action.href}`} className="focus:outline-none">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        {action.title}
                                    </a>
                                    :
                                    <Link to={action.href} className="focus:outline-none">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        {action.title}
                                    </Link>
                                }
                            </h3>
                            <p className="mt-2 text-sm text-gray-500">
                                {action.description}
                            </p>
                        </div>
                        <span
                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                            aria-hidden="true"
                        >
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                        </span>
                    </div>
                ))}
            </div>
        </main>
    )
}