import { Fragment, useEffect, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Combobox, Dialog, Transition } from '@headlessui/react'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UserSearch(props) {
    const [query, setQuery] = useState('')

    const filteredPeople =
        query === ''
            ? []
            : props.people.filter((user) => {
                    const userFullName = user.firstname + ' ' + user.lastname
                    return userFullName.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <Combobox onChange={(e) => props.inviteUser(e)}>
            <div className="relative bg-white rounded-lg shadow-md">
                <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400 bg-white"
                    aria-hidden="true"
                />
                <Combobox.Input
                    className=" bg-white h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Zoeken..."
                    onChange={(event) => setQuery(event.target.value)}
                />
            </div>

            {filteredPeople.length > 0 && (
                <Combobox.Options static className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 bg-white rounded-lg">
                    {filteredPeople.map((person) => (
                        <Combobox.Option
                            key={person.id}
                            value={person}
                            className={({ active }) =>
                                classNames('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                            }
                        >
                            {/* Make letter bold if it is in query */}
                            {person.firstname.split('').map((letter, index) => {
                                if (query.toLowerCase().includes(letter.toLowerCase())) {
                                    return <span className="font-bold">{letter}</span>
                                } else {
                                    return <span>{letter}</span>
                                }
                            })}
                            {' '}
                            {person.lastname.split('').map((letter, index) => {
                                if (query.toLowerCase().includes(letter.toLowerCase())) {
                                    return <span className="font-bold">{letter}</span>
                                } else {
                                    return <span>{letter}</span>
                                }
                            })}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            )}

            {query !== '' && filteredPeople.length === 0 && (
                <p className="p-4 text-sm text-gray-500">Geen gebruiker(s) gevonden.</p>
            )}
        </Combobox>
    )
}