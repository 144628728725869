import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowLeftOnRectangleIcon,
  ArrowUturnLeftIcon,
  BackwardIcon,
  Bars3Icon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
  BuildingOffice2Icon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/outline'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil';

//import assets
import { ReactComponent as AssembloLogo } from '../../assets/img/assemblo-logo.svg'

//import services
import AuthService from "../../services/auth.service"

//import datastores
import { loggedInState } from '../../data/loggedIn.atom';
import { meState } from '../../data/me.atom';

const navigation = [
  { name: 'Dashboard', uri: ['', undefined], icon: HomeIcon },
  { name: 'Gebruikers', uri: ['users'], icon: UsersIcon },
  { name: 'Gebouwen', uri: ['buildings'], icon: BuildingOffice2Icon },
  { name: 'Meetings', uri: ['meetings', 'meeting'], icon: CalendarIcon },
  { name: 'Televisie', uri: ['television'], icon: ComputerDesktopIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminMenu() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()

  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const [me, setMe] = useRecoilState(meState);


  function logout() {
    AuthService.logout()
    setLoggedIn(false);
  }

  return (
    <>
      <div className='bg-gray-50'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <AssembloLogo className="h-28 w-auto mx-auto" />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          to={item.uri[0]}
                          key={item.name}
                          className={classNames(
                            item.uri.includes(location.pathname.split("/")[2])
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.uri.includes(location.pathname.split("/")[2])
                                ? 'text-white' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 bg-gray-700 p-4">
                    <a href="#" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                            <span className="font-medium leading-none text-white">LM</span>
                          </span>
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-white">Tom Cook</p>
                          <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <AssembloLogo className="h-28 w-auto mx-auto" />
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    to={item.uri[0]}
                    key={item.name}
                    className={classNames(
                      item.uri.includes(location.pathname.split("/")[2])
                        ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.uri.includes(location.pathname.split("/")[2])
                          ? 'text-white' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
            <nav className="mt-5 px-2 mb-2 border-t border-gray-700">
              <Link
                to={"/"}
                key="Homescreen"
                className={classNames(
                  'text-gray-300 hover:bg-gray-700 hover:text-white mt-2',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
              >
                <ArrowUturnLeftIcon
                  className={classNames(
                    'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                Homescreen
              </Link>
              <a
                onClick={() => { logout() }}
                className={classNames(
                  'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
              >
                <ArrowLeftOnRectangleIcon
                  className={classNames(
                    'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                Uitloggen
              </a>
            </nav>
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <a href="#" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                      <span className="font-medium leading-none text-white">{me.firstname[0]}{me.lastname[0]}</span>
                    </span>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{me.firstname}{' '}{me.lastname}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64 h-full">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  )
}