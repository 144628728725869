import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

function Toggle({ options, defaultChecked }) {
    const { t } = useTranslation();
    
    const handleChange = (e) => {
        if (e.target.checked) {
            return options[1].value();
        }
        return options[0].value();
    };
    return (
        <div className="flex justify-start">
        <div className="">
            <p
                className="text-xs font-medium text-slate-300 underline align-middle cursor-pointer mt-1"
                onClick={() => {window.location.reload()}}
            >
                {t("meeting.busy.refresh")}</p>
        </div>
        <div className="flex items-center justify-end ml-auto mr-2">
            <span class={`mr-1 text-sm font-medium text-white dark:text-gray-300 ${defaultChecked == false ? "font-bold" : null}`}>
                {options[0].label}
            </span>

            <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" defaultChecked={defaultChecked} onChange={(e) => handleChange(e)} class="sr-only peer" />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#7f61d2]"></div>
                
                <span class={`ml-1 text-sm font-medium text-white dark:text-gray-300 ${defaultChecked ? "font-bold" : null}`}>
                    {options[1].label}
                </span>
            </label>
        </div>
        </div>
    );
}

export default Toggle;
