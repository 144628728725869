import platform from "platform";
import React, { useEffect, useState } from "react";
import Chrome from "../assets/img/browser-help/Chrome.svg";
import FireFox from "../assets/img/browser-help/Firefox.svg";
import HeaderComponent from "../components/header.component";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

import MicrosoftEdge from "../assets/img/browser-help/Edge.svg";
import RunTeamviewer from "../assets/img/browser-help/run.png";
import TeamviewerLogo from "../assets/img/browser-help/teamviewer.svg";
import TeamviewerCode from "../assets/img/browser-help/teamviewercode.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

function HelpView() {
    let language, supportsWebSockets, isIE;
    let ua = window.navigator.userAgent;
    let navigator = window.navigator;
    const cookies = navigator?.cookieEnabled.toString();
    language = navigator.language.toString();
    const [isWebsockSupported, setisWebsockSupported] = useState(false);
    const { height, width } = useWindowDimensions();
    const { t } = useTranslation();

    useEffect(() => {
        supportsWebSockets = (
            "WebSocket" in window || "MozWebSocket" in window
        ).toString();
        setisWebsockSupported(supportsWebSockets);
    }, []);

    return (
        <section className="bg-gray-50 mx-auto h-full">
            <HeaderComponent
                subtext={t("help.header.subtext")}
                text={"Waarmee kunnen we je helpen?"}
                explainer={false}
            />
            <main className="w-full mx-auto absolute -mt-80 z-30">
                <div className="w-11/12 max-w-4xl mx-auto py-6 sm:px-6 lg:px-8 s">
                    <div className="px-20 py-6 bg-white rounded-2xl shadow-md help-page">
                        {/* <!-- {#if isIE} --> */}
                        <h2 className="text-2xl font-bold mt-8">
                            {t("help.unsupported.title")}
                        </h2>
                        <p>
                            {t("help.unsupported.sub1")}
                        </p>

                        <ul>
                            <li>
                                <a
                                    href="https://www.google.com/chrome/"
                                    target="_blank"
                                    className="browser"
                                >
                                    <img src={Chrome} alt="chrome logo" />
                                    <span className="bold">Download</span>
                                    <span>Google Chrome</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.mozilla.org/firefox/new/"
                                    target="_blank"
                                    className="browser"
                                >
                                    <img src={FireFox} alt="firefox logo" />
                                    <span className="bold">Download</span>
                                    <span>Firefox</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.microsoft.com/edge"
                                    target="_blank"
                                    className="browser"
                                >
                                    <img src={MicrosoftEdge} alt="edge logo" />
                                    <span className="bold">Download</span>
                                    <span>Microsoft Edge</span>
                                </a>
                            </li>
                        </ul>
                        {/* <!-- {/if} --> */}
                        <p>{t("help.unsupported.sub2")}</p>

                        <a
                            href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=nl"
                            className="install_link"
                            target="_blank"
                        >
                            {t("help.unsupported.chrome")}
                        </a>
                        <a
                            href="https://support.mozilla.org/nl/kb/firefox-downloaden-en-installeren-windows"
                            className="install_link"
                            target="_blank"
                        >
                            {t("help.unsupported.firefox")}{" "}
                        </a>
                        <a
                            href="https://www.microsoft.com/en-us/edge"
                            className="install_link"
                            target="_blank"
                        >
                            {t("help.unsupported.edge")}{" "}
                        </a>

                        <h2 className="text-2xl font-bold pt-8">
                        {t("help.help.title")}
                        </h2>
                        <p>
                        {t("help.help.sub1")}
                        </p>

                        <ol className="list-decimal">
                            <li>
                                <p>{t("help.help.teamviewer")}</p>
                                <a
                                    href="https://download.teamviewer.com/download/TeamViewerQS.exe"
                                    target="_blank"
                                    className="browser"
                                >
                                    <img
                                        className="team-viewer-logo"
                                        src={TeamviewerLogo}
                                        alt="teamviewer logo"
                                    />
                                    <span className="bold">Download</span>
                                    <span>TeamViewer</span>
                                </a>
                            </li>
                            <li>
                                <p>
                                {t("help.help.teamviewer-explainer")}
                                </p>
                                <div className="click-run">
                                    <img src={RunTeamviewer} alt="press run" />
                                </div>
                            </li>

                            <li>
                                <p>{t("help.help.teamviewer-explainer2")}</p>
                                <img src={TeamviewerCode} alt="rember this code" />
                            </li>
                            <li>
                                <p>{t("help.help.call-us")}</p>
                                <p>
                                    <span className="bold">
                                        {/* TEMPORARELY REMOVED David Gistelinck (<a href="tel:+32 498 66 35 43">+32 498 66 35 43</a>)<br/> */}
                                        Yentl Prieels (<a href="tel:+32 494 25 83 76">+32 494 25 83 76</a>)<br/>
                                        Louis Mylle (<a href="tel:+32 472 71 46 37">+32 472 71 46 37</a>)
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                {t("help.help.information-explainer")}
                                </p>
                                <code>
                                    <ul>
                                        <li>Browser: {platform.name}</li>
                                        <li>Version: {platform.version}</li>
                                        <li>OS: {platform.os.family}</li>
                                        <li>Version: {platform.os.version}</li>
                                        <li>Websockets enabled: {isWebsockSupported}</li>
                                        <li>Cookies enabled: {cookies}</li>
                                        <li>Language: {language}</li>
                                        <li>
                                            Browser size: {width} x {height}
                                        </li>
                                    </ul>
                                </code>
                            </li>
                        </ol>
                    </div>
                </div>
            </main>
        </section>
    );
}

export default HelpView;
