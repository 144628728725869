import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { i18n } from "i18next";

//import services
import ReviewService from "../services/review.service";
import BackgroundBlocks from "../assets/img/bg-blocks.svg";

export default function EndedComponent(props) {
    const { t } = useTranslation();

    const [vote, setVote] = useState(0);
    const [comment, setComment] = useState(null);
    const [commented, setCommented] = useState(false);

    useEffect(() => {
        ReviewService.get(props.meeting_id)
            .then((response) => {
                setVote(response.data.vote ?? 0);
                setComment(response.data.comment ?? null);
                if (response.data.comment) {
                    setCommented(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    function placeVote(score) {
        setVote(score);
        ReviewService.createReview(props.meeting_id, score)
            .then((response) => {
                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function placeComment() {
        ReviewService.addComment(props.meeting_id, comment)
            .then((response) => {
                setCommented(true)
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <section
            style={{
                minHeight: "200px",
                backgroundImage: "url(" + BackgroundBlocks + ")",
                backgroundColor: "#392b5f",
                background: ` center center no-repeat, radial-gradient(circle, rgb(57, 43, 95), rgb(50, 21, 72)); purple`,
            }}
            className=" bg-gradient-to-r from-[#392b5f] to-[#321548] h-screen w-screen mx-auto "
        >
            <div className="w-1/2 mx-auto text-center h-full text-white flex flex-col justify-center items-center">
                <h3 className="text-4xl font-bold">
                    {t("meeting.end.thanks")}
                </h3>
                <h5 className="text-xl font-semibold mt-10">{t("meeting.end.repeat")}</h5>
                {vote === 0 ? (
                    <div className="mt-4 w-full flex gap-x-4 justify-center text-5xl  items-center text-center">

                        <button
                            onClick={() => {
                                placeVote(1);
                            }}
                            className="transition-all hover:text-6xl"
                        >
                            😍
                        </button>
                        <button
                            onClick={() => {
                                placeVote(2);
                            }}
                            className="transition-all hover:text-6xl"

                        >
                            👏
                        </button>
                        <button
                            onClick={() => {
                                placeVote(3);
                            }}
                            className="transition-all hover:text-6xl"

                        >
                            😐
                        </button>
                        <button
                            onClick={() => {
                                placeVote(4);
                            }}
                            className="transition-all hover:text-6xl"

                        >
                            😡
                        </button>
                    </div>
                ) : (
                    <>
                        <div className="">
                            {vote == 1 ? <div className="text-5xl">😍</div> : null}
                            {vote == 2 ? <div className="text-5xl">👏</div> : null}
                            {vote == 3 ? <div className="text-5xl">😐</div> : null}
                            {vote == 4 ? <div className="text-5xl">😡</div> : null}
                        </div>
                        {!commented
                            ?
                            <div className="mt-16 w-3/4">
                                <textarea
                                    id="message"
                                    rows="4"
                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                                    placeholder={t("meeting.end.feedback")}
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e.target.value);
                                    }}
                                ></textarea>
                                <button
                                    onClick={() => {
                                        placeComment();
                                    }}
                                    className="button-primary-blue text-sm mt-4"
                                >
                                    {t("meeting.end.send")}
                                </button>
                            </div>
                            :
                            <>
                                <p className="font-bold mt-16">{t("meeting.end.sent")}:</p>
                                <p className="italic">
                                    "{comment}"
                                </p>
                            </>
                        }
                    </>
                )}

                <div className="mt-16">
                    <Link to={"/"} className="underline text-white">
                        {t("meeting.end.back")}
                    </Link>
                </div>
            </div>
        </section>
    );
}
