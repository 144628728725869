import { useState, useEffect, Fragment } from 'react';
import {
    CloudArrowUpIcon,
    PlusCircleIcon,
    DocumentMagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react'

//services
import BuildingService from '../../services/building.service';
import { toast } from 'react-toastify';

export default function AdminBuildingsView() {
    const [modalOpen, setModalOpen] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [contracts, setContracts] = useState([]);

    //individual building
    const [image, setImage] = useState(null);
    const [imageLink, setImageLink] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const [type, setType] = useState("");
    const [proxyDutch, setProxyDutch] = useState(0);
    const [proxyFrench, setProxyFrench] = useState(0);
    const [onlineDutch, setOnlineDutch] = useState(0);
    const [onlineFrench, setOnlineFrench] = useState(0);

    const [upload, setUpload] = useState(false);

    useEffect(() => {
        document.title = "Assemblo - Admin - Gebouwen"
        BuildingService.getBuildings()
            .then(response => {
                setBuildings(response.data);
                console.log(response.data)
            }
            )
            .catch(err => {
                console.log(err);
            }
            );

        BuildingService.getContracts()
            .then(response => {
                setContracts(response.data);
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    function uploadFile(file) {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            BuildingService.uploadFile(formData)
                .then(response => {
                        setImageLink(response.data);
                        setUpload(true);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    function createBuilding() {
        if (!type || !name || !description || !address || !image ) {
            toast.error("Please fill in all fields");
        } else {
            if (type == "VME" && (proxyDutch <= 0 || proxyFrench <=0 || onlineDutch <= 0 || onlineFrench <= 0)) {
                toast.error("Please fill in all fields");
            } else if (type == "BV" && (proxyDutch <= 0 || proxyFrench <=0)) {
                toast.error("Please fill in all fields");
            } else {
                uploadFile(image);
            }
        } 
    }

    useEffect(() => {
        if (upload) {
            BuildingService.createBuilding({
                image: imageLink,
                name: name,
                description: description,
                address: address,
                type: type,
                proxyDutch: proxyDutch,
                proxyFrench: proxyFrench,
                onlineDutch: onlineDutch,
                onlineFrench: onlineFrench,
            })
                .then(response => {
                    setBuildings([...buildings, response.data]);
                    closeModal();
                }
                )
                .catch(err => {
                    console.log(err);
                }
                );
        }
    }, [upload]);

    function openDocument(id) {
        window.open(process.env.REACT_APP_DOCUSIGN_URI + 'documents/templates/' + id, "_blank");
    }

    function closeModal() {
        setModalOpen(false);
        setImage(null);
        setName("");
        setDescription("");
        setAddress("");
        setType("");
        setProxyDutch(null);
        setProxyFrench(null);
        setOnlineDutch(null);
        setOnlineFrench(null);
        setUpload(false);
    }

    return (
        <main className="flex-1">
            <div className="py-6">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 sm:flex sm:items-center sm:justify-between">
                    <h1 className="text-2xl font-semibold text-gray-900">Gebouwen</h1>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <button
                            type="button"
                            onClick={(e) => setModalOpen(true)}
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Nieuw gebouw toevoegen
                        </button>
                    </div>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <p>Hieronder een lijst van alle Assemblo-gebouwen.</p>
                    <br />
                    <p className="text-gray-400 text-sm">Bij het aanmaken van een AV wordt gevraagd welke DocuSign contracten je wilt koppelen. Contacteer Louis Mylle om je contract klaar te zetten in Docusign en te koppelen.</p>

                    {/* content */}
                    <div className="mt-10">
                        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                            {buildings.map((building) => (
                                <li
                                    key={building.id}
                                    className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                                >
                                    <div className="flex flex-1 flex-col">
                                        <img className="flex-shrink-0 rounded-t-lg" src={process.env.REACT_APP_API_URI + "file/download/" + building.image} alt="" />
                                        <div className="pt-4">
                                            <h3 className="text-lg font-bold text-gray-900 capitalize">{building.name} <span className="font-normal text-gray-600 text-xs">({building.type})</span></h3>
                                            <dl className="mt-1 mb-5 flex flex-grow flex-col justify-between mx-1">
                                                <dd className="text-sm text-gray-800">{building.description}</dd>
                                                <dd className="text-sm text-gray-500">{building.address}</dd>

                                            </dl>
                                        </div>
                                        {building.type == "VME" ? (
                                                <div className="-mt-px flex divide-x divide-gray-200 border-b border-t">
                                                    <div className="flex w-0 flex-1">
                                                        <a
                                                            onClick={() => openDocument(contracts.find(contract => contract.id == building.onlineDutch_id).template_id)}
                                                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                                                        >
                                                            <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            <span className="ml-3">Online (NL)</span>
                                                        </a>
                                                    </div>
                                                    <div className="-ml-px flex w-0 flex-1">
                                                        <a
                                                            onClick={() => openDocument(contracts.find(contract => contract.id == building.onlineFrench_id).template_id)}
                                                            className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                                                        >
                                                            <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            <span className="ml-3">Online (FR)</span>
                                                        </a>
                                                    </div>
                                                </div>) : null}
                                                {building.type == "VME" || building.type == "BV" ? (
                                        <div className="mt-auto">
                                            <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                                <div className="flex w-0 flex-1">
                                                    <a
                                                        onClick={() => openDocument(contracts.find(contract => contract.id == building.proxyDutch_id).template_id)}
                                                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                                                    >
                                                        <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span className="ml-3">Volmacht (NL)</span>
                                                    </a>
                                                </div>
                                                <div className="-ml-px flex w-0 flex-1">
                                                    <a
                                                        onClick={() => openDocument(contracts.find(contract => contract.id == building.proxyFrench_id).template_id)}
                                                        className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                                                    >
                                                        <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span className="ml-3">Volmacht (FR)</span>
                                                    </a>
                                                </div>
                                            </div>
                                        
                                        </div>) : null}
                                    </div>
                                </li>
                            ))}
                        </ul>



                    </div>

                </div>
            </div>

            {/* MODAL */}
            <Transition.Root show={modalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <div className="mt-1">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Gebouw toevoegen
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Vul onderstaande velden aan om een nieuw gebouw toe te voegen.
                                                </p>
                                            </div>
                                            <div>
                                                <form className="mt-6 grid grid-cols-1 gap-y-6">
                                                    <div class="">
                                                        <label htmlFor="" className="mb-1 block text-sm font-medium text-gray-700">
                                                            Afbeelding
                                                        </label>
                                                        {!image ?
                                                            <label
                                                                className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                                                <span class="flex items-center space-x-2 text-gray-400">
                                                                    <CloudArrowUpIcon className="w-6 h-6" />
                                                                    <span class="font-medium">
                                                                        Sleep je bestand hierheen of&nbsp;
                                                                        <span class="text-indigo-600">selecteer</span>.
                                                                    </span>
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    name="image"
                                                                    className="hidden"
                                                                    onChange={(event) => setImage(event.target.files[0])}
                                                                    accept="image/*"
                                                                    multiple={false}
                                                                />
                                                            </label>
                                                            :
                                                            <div className="justify-center px-4 pb-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                                                <img src={URL.createObjectURL(image)} className="" />
                                                                <a className="text-xs text-gray-400 underline" onClick={() => setImage()}>Verwijder geselecteerd bestand</a>
                                                            </div>
                                                        }

                                                        <label htmlFor="name" className="mt-5 block text-sm font-medium text-gray-700">
                                                            Naam
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setName(e.target.value))}
                                                                value={name}
                                                            />
                                                        </div>

                                                        <label htmlFor="description" className="mt-5 block text-sm font-medium text-gray-700">
                                                            Beschrijving
                                                        </label>
                                                        <div className="mt-1">
                                                            <textarea
                                                                type="text"
                                                                name="description"
                                                                id="description"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setDescription(e.target.value))}
                                                                value={description}
                                                            />
                                                        </div>

                                                        <label htmlFor="name" className="mt-5 block text-sm font-medium text-gray-700">
                                                            Adres
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                name="adres"
                                                                id="adres"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setAddress(e.target.value))}
                                                                value={address}
                                                            />
                                                        </div>

                                                        <label htmlFor="type" className="mt-5 block text-sm font-medium text-gray-700">
                                                            Type
                                                        </label>
                                                        <div className="mt-1">
                                                            <select
                                                                id="type"
                                                                name="type"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setType(e.target.value))}
                                                                value={type}
                                                                placeholder="Selecteer het type"
                                                            >
                                                                <option value="" disabled>Selecteer het type</option>
                                                                <option value="BV">BV</option>
                                                                <option value="VME">VME</option>
                                                                <option value="IV">Infovergadering</option>
                                                            </select>
                                                        </div>

                                                        {type == "BV" || type == "VME" ?
                                                            <div>
                                                                <label htmlFor="onlineDutch" className="mb-1 mt-5 block text-sm font-medium text-gray-700">
                                                                    Volmacht (NL)
                                                                </label>
                                                                <select
                                                                    id="volmachtNL"
                                                                    name="volmachtNL"
                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Selecteer een contract..."
                                                                    onChange={e => (setProxyDutch(e.target.value))}
                                                                >
                                                                    <option value="Selecteer een contract..." default disabled>Selecteer een contract...</option>
                                                                    {contracts.map((contract) => (
                                                                        <option value={contract.id}>{contract.name}</option>
                                                                    ))}
                                                                </select>

                                                                <label htmlFor="onlineDutch" className="mb-1 mt-5 block text-sm font-medium text-gray-700">
                                                                    Volmacht (FR)
                                                                </label>
                                                                <select
                                                                    id="volmachtFR"
                                                                    name="volmachtFR"
                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Selecteer een contract..."
                                                                    onChange={e => (setProxyFrench(e.target.value))}
                                                                >
                                                                    <option value="Selecteer een contract..." default disabled>Selecteer een contract...</option>
                                                                    {contracts.map((contract) => (
                                                                        <option value={contract.id}>{contract.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            : null}

                                                        {type == "VME" ?
                                                            <div>
                                                                <label htmlFor="onlineDutch" className="mb-1 mt-5 block text-sm font-medium text-gray-700">
                                                                    Contract online (NL)
                                                                </label>
                                                                <select
                                                                    id="volmachtOnlineNL"
                                                                    name="volmachtOnlineNL"
                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Selecteer een contract..."
                                                                    onChange={e => (setOnlineDutch(e.target.value))}
                                                                >
                                                                    <option value="Selecteer een contract..." default disabled>Selecteer een contract...</option>
                                                                    {contracts.map((contract) => (
                                                                        <option value={contract.id}>{contract.name}</option>
                                                                    ))}
                                                                </select>

                                                                <label htmlFor="onlineDutch" className="mb-1 mt-5 block text-sm font-medium text-gray-700">
                                                                    Contract online (FR)
                                                                </label>
                                                                <select
                                                                    id="volmachtOnlineFR"
                                                                    name="volmachtOnlineFR"
                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Selecteer een contract..."
                                                                    onChange={e => (setOnlineFrench(e.target.value))}
                                                                >
                                                                    <option value="Selecteer een contract..." default disabled>Selecteer een contract...</option>
                                                                    {contracts.map((contract) => (
                                                                        <option value={contract.id}>{contract.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-12 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                            onClick={() => createBuilding()}
                                        >
                                            Aanmaken
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => closeModal()}
                                        >
                                            Annuleer
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </main>
    )
}