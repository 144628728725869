import React from "react";
import Not from "../assets/img/rsvp/rsvp-not.svg";
import Online  from "../assets/img/rsvp/rsvp-online.svg";
import Present from "../assets/img/rsvp/rsvp-present.svg";
import Proxy from "../assets/img/rsvp/rsvp-proxy.svg";
import cn from 'classnames'
import { t } from "i18next";

function LegendMeetingStatus({ darkMode = false }) {
    return (
        <div className="w-full">
            <div className={cn("flex justify-center gap-x-1 items-center relative mb-4", {
                "text-slate-700": !darkMode,
                "text-white": darkMode,
            })}>
                <Icon text={t("rsvp.fysical")}>
                    <img src={Present} className={cn("opacity-40", {
                        "opacity-90": darkMode
                    })} />
                </Icon>
                <Icon text={t("rsvp.online")}>
                    <img src={Online} className={cn("opacity-40", {
                        "opacity-90": darkMode
                    })} />
                </Icon>
                <Icon text={t("rsvp.absent")}>
                    <img src={Not} className={cn("opacity-40", {
                        "opacity-90": darkMode
                    })} />
                </Icon>
                <Icon text={t("rsvp.proxy")}>
                    <img src={Proxy} className={cn("opacity-40", {
                        "opacity-90": darkMode
                    })} />
                </Icon>
            </div>
        </div>
    );
}

const Icon = ({ text, children }) => (
    <div className="relative h-28 w-28 flex justify-center items-center">
        {children}
        <div className="absolute opacity-100 z-10 flex justify-center items-center text-center font-bold text">
            {text}
        </div>
    </div>
);

export default LegendMeetingStatus;
