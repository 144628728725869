import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import lang from './assets/lang/lang.json'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: lang,
        fallbackLng: "nl",

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;