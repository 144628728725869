import {
  UsersIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  CommandLineIcon
} from '@heroicons/react/20/solid'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Outlet, Link, useParams, useNavigate, useLocation } from 'react-router-dom'

const tabs = [
  { name: 'Beheer', uri: ['', undefined], icon: CommandLineIcon},
  { name: 'Livestream' , uri: ['livestream'], icon: ChartBarIcon},
  { name: 'Genodigden', uri: ['invitees'], icon: UsersIcon},
  { name: 'Instellingen', uri: ['settings'], icon: Cog6ToothIcon},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminTabMenu() {
  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation()


  return (
    <div className='h-full'>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            onChange={(e) => {navigate("/admin/meeting/" + id + "/" + e.target.value)}}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.uri[0]}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8 px-5" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  to={"/admin/meeting/" + id + "/" + tab.uri[0]}
                  key={tab.name}
                  className={classNames(
                    tab.uri.includes(location.pathname.split("/")[4])
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.uri.includes(location.pathname.split("/")[4]) ? 'page' : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.uri.includes(location.pathname.split("/")[4]) ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </Link>
              ))}
              <Link
                  to={"/preview/" + id + "/"}
                  target='_blank'
                  key="preview"
                  className='border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                >
                  <ArrowTopRightOnSquareIcon
                    className='text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5'
                    aria-hidden="true"
                  />
                  <span>Zaalview</span>
                </Link>
            </nav>
          </div>
        </div>
      </div>
      <Outlet />
      </div>
  )
}