import api from "./api/api";
import TokenService from "./api/token";

class SignService {
  getSigns(rsvp) {
    return api.get("/av/sign/list/" + rsvp + "/");
  }

  signContract(attendId, rsvp, lang) {
    return api.post("/av/sign/signage/", 
    { 
      attend_id: attendId,
      rsvp: rsvp,
      lang: lang
    });
  }

  getAdminSigns(id) {
    return api.get("/av/sign/admin/list/" + id + "/");
  }

  downloadPdf(id) {
    return api.get("/av/sign/pdf/" + id + "/", { responseType: 'blob' });
  }
}

export default new SignService();