import { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Dialog, Transition, Switch } from '@headlessui/react'
import {
    CommandLineIcon,
    SquaresPlusIcon
} from '@heroicons/react/20/solid'

//import services
import MeetingService from "../../services/meeting.service";
import meetingAdminService from "../../services/meeting.admin.service";
import BuildingService from "../../services/building.service";

export default function AdminMeetingsView() {
    const [meetings, setMeetings] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [building, setBuilding] = useState("");


    useEffect(() => {
        document.title = "Assemblo - Admin - AV's"

        meetingAdminService.getAllMeetings()
            .then(response => {
                setMeetings(response.data);
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });

        BuildingService.getBuildings()
            .then(response => {
                console.log(response.data)
                setBuildings(response.data);
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });

    }, []);

    function joinMeeting(id, type) {
        MeetingService.joinMeeting(id)
            .then(response => {
                if (type == "manage") {
                    navigate("/admin/meeting/" + id);
                } else if (type == "settings") {
                    navigate("/admin/meeting/" + id + "/settings");
                }
            })
            .catch(err => {
                toast.error(err.response.data.message);
            }
            );
    }

    function createMeeting() {
        if (name && date && time) {
            meetingAdminService.createMeeting(name, date, time, building)
                .then(response => {
                    setMeetings(meetings.concat(response.data));
                    closeModal();
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                });
        } else {
            toast.error("Vul alle velden in");
        }
    }

    function closeModal() {
        setModalOpen(false);
        setName("");
        setDate("");
        setTime("");
    }

    return (
        <main className="flex-1">
            <div className="py-6">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 sm:flex sm:items-center sm:justify-between">
                    <h1 className="text-2xl font-semibold text-gray-900">Online vergaderingen</h1>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <button
                            type="button"
                            onClick={(e) => setModalOpen(true)}
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <SquaresPlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Nieuwe AV aanmaken
                        </button>
                    </div>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <p>Hieronder vind je alle AV's terug in het systeem.</p>
                    <br />
                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-5">
                        {meetings
                            ? meetings.map(meeting => {
                                return (
                                    <>
                                        <li key={meeting.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                                <div className="flex-1 truncate">
                                                    <div className="flex items-center space-x-3">
                                                        <h3 className="truncate text-lg font-medium text-gray-900">{meeting.title}</h3>
                                                        <span className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${meeting.started && !meeting.ended ? "text-green-800 bg-green-100" : !meeting.started && meeting.ended ? "text-red-800 bg-red-100" : "text-gray-800 bg-gray-100"}`}>
                                                            {meeting.started && !meeting.ended ? "LIVE" : !meeting.started && meeting.ended ? "AFGELOPEN" : "NIET GESTART"}
                                                        </span>
                                                    </div>
                                                    <p className="mt-1 truncate text-sm text-gray-500">{meeting.date}</p>
                                                </div>
                                                <div className="flex space-x-2">
                                                    <button
                                                        onClick={() => joinMeeting(meeting.id, "settings")}
                                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Wijzigen
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => joinMeeting(meeting.id, "manage")}
                                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Beheren
                                                        <CommandLineIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    </>

                                )

                            })

                            : <p>no meetings</p>
                        }
                    </ul>
                </div>
            </div>
            {/* MODAL */}
            <Transition.Root show={modalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <div className="mt-1">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                AV aanmaken
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Vul onderstaande velden in om een nieuwe AV aan te maken.
                                                    Wij doen de rest 😉
                                                </p>
                                            </div>
                                            <div>
                                                <form className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                                    <div className="col-span-2">
                                                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                                            Gebouw
                                                        </label>
                                                        <select
                                                            id="building"
                                                            name="building"
                                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            onChange={(e) => setBuilding(e.target.value)}
                                                            defaultValue={0}
                                                        >
                                                            <option value="0" disabled>Selecteer een gebouw</option>
                                                            {buildings.map((building) => {
                                                                return (
                                                                    <option key={building.id} value={building.id}>{building.name} ({building.type})</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            Datum
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="date"
                                                                name="date"
                                                                id="date"
                                                                autoComplete="given-name"
                                                                className="w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => setDate(e.target.value)}
                                                                value={date}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            Tijd
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="time"
                                                                name="time"
                                                                id="time"
                                                                autoComplete="given-name"
                                                                className="w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setTime(e.target.value))}
                                                                value={time}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label htmlFor="firstname" className="mt-5 block text-sm font-medium text-gray-700">
                                                            Titel
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setName(e.target.value))}
                                                                value={name}
                                                                onFocus={() => setName('AV - ' + buildings.find(fbuilding => fbuilding.id == building).name)}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-12 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                            onClick={() => createMeeting()}
                                        >
                                            Aanmaken
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => closeModal()}
                                        >
                                            Annuleer
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </main>
    )
}