import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import VideoComponent from "../../components/ui/video.component";
import {
    PlusCircleIcon,
    Square2StackIcon,
} from '@heroicons/react/20/solid';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

// import services
import televisionService from "../../services/television.service";
import { toast } from "react-toastify";

export default function AdminTelevisionView() {
    const [videoView, setVideoView] = useState();
    const [video, setVideo] = useState(false);

    const [videos, setVideos] = useState([]);

    const [open, setOpen] = useState(true)

    useEffect(() => {
        televisionService.getAll().then((response) => {
            setVideos(response.data);
            console.log(response.data);
        });
    }, []);

    function saveVideos() {
        televisionService.update(videos)
            .then((response) => {
                toast.success("Wachttelevisie succesvol aangepast.");
            })
            .catch((error) => {
                toast.error("Er is iets fout gegaan bij het aanpassen van de wachttelevisie.");
            });
    }

    return (
        <main className="flex-1">
            {/* TEMPORARY MODAL */}
            <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Feature disabled</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            This feature has been disabled by an administrator.<br/>
                                            Content can still be edited but is not displayed due to a recent design change.
                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>


            <div className="py-6">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 sm:flex sm:items-center sm:justify-between">
                    <h1 className="text-2xl font-semibold text-gray-900">Wachttelevisie</h1>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <button
                            type="button"
                            onClick={(e) => saveVideos()}
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <Square2StackIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Opslaan
                        </button>
                    </div>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <p>Pas hieronder de inhoud van de wachttelevisie aan.</p>
                    <p className='mt-2 text-gray-400 text-sm'>
                        Als de link "http" bevat wordt deze geopend in een nieuw tablad.
                        <br />Een YouTube video kan ook worden toegevoegd door de video ID in te vullen: https://www.youtube.com/watch?v=<span className='font-bold'>VIDEO-ID</span>.
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 px-4 sm:px-6 md:px-8 mt-10">
                    {videos.map((video, index) => (
                        <div>
                            <div>
                                <label htmlFor="titel_nl" className="block text-sm font-medium leading-6 text-gray-900">
                                    Titel (NL)
                                </label>
                                <div className="">
                                    <input
                                        id="titel_nl"
                                        name="titel_nl"
                                        type="text"
                                        placeholder="Titel (NL)"
                                        value={video.title_nl}
                                        onChange={(e) => {
                                            const updatedVideos = [...videos];
                                            updatedVideos[index] = { ...video, title_nl: e.target.value };
                                            setVideos(updatedVideos);
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="titel_fr" className="mt-2 block text-sm font-medium leading-6 text-gray-900">
                                    Titel (FR)
                                </label>
                                <div className="">
                                    <input
                                        id="titel_fr"
                                        name="titel_fr"
                                        type="text"
                                        placeholder="Titel (FR)"
                                        value={video.title_fr}
                                        onChange={(e) => {
                                            const updatedVideos = [...videos];
                                            updatedVideos[index] = { ...video, title_fr: e.target.value };
                                            setVideos(updatedVideos);
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="titel_en" className="mt-2 block text-sm font-medium leading-6 text-gray-900">
                                    Titel (EN)
                                </label>
                                <div className="">
                                    <input
                                        id="titel_en"
                                        name="titel_en"
                                        type="text"
                                        placeholder="Titel (EN)"
                                        value={video.title_en}
                                        onChange={(e) => {
                                            const updatedVideos = [...videos];
                                            updatedVideos[index] = { ...video, title_en: e.target.value };
                                            setVideos(updatedVideos);
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="url" className="mt-2 block text-sm font-medium leading-6 text-gray-900">
                                    URL
                                </label>
                                <div className="">
                                    <input
                                        id="url"
                                        name="url"
                                        type="text"
                                        placeholder="www.example.com"
                                        value={video.content}
                                        onChange={(e) => {
                                            const updatedVideos = [...videos];
                                            updatedVideos[index] = { ...video, content: e.target.value };
                                            setVideos(updatedVideos);
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                <div className="mt-20 mx-auto px-4 sm:px-6 lg:px-8 sm:flex sm:items-center sm:justify-between">
                    <h1 className="text-2xl font-semibold text-gray-900">Voorbeeld</h1>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <p>Een exact voorbeeld van hoe de wachttelevisie eruit ziet en werkt.</p>
                </div>
                <div className='mt-20'>
                    <div style={{ maxWidth: "320px", width: "50%", margin: "0 auto" }}>
                        <div className="embed-container" style={{ margin: "25% auto 20%" }}>
                            <div className="embed options">
                                {videos.map((video) => (
                                    <button
                                        className="btn option"
                                        onClick={() => setVideoView(video.content) & setVideo(true)}
                                    >
                                        {video.title_nl}
                                    </button>
                                ))}
                            </div>
                            <div className="tv" />
                        </div>
                    </div>
                    <VideoComponent uri={videoView} open={video} setOpen={setVideo} />
                </div>
            </div>
        </main >
    )
}