import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid"
import { ArrowDownCircleIcon, ArrowDownIcon, ArrowUpCircleIcon, ArrowUpIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"

export default function VoteComponent(props) {
    const [yes, setYes] = useState(0)
    const [no, setNo] = useState(0)
    const [abstain, setAbstain] = useState(0)

    useEffect(() => {
        if (props.voting && props.voting.votes) {
            props = props.voting
            props.votes.forEach(vote => {
                if (vote.response == "yes") {
                    setYes(yes => yes + 1)
                }
                if (vote.response == "no") {
                    setNo(no => no + 1)
                }
                if (vote.response == "abstain") {
                    setAbstain(abstain => abstain + 1)
                }
            })
            props.manualvotes.forEach(vote => {
                if (vote.response == "yes") {
                    setYes(yes => yes + 1)
                }
                if (vote.response == "no") {
                    setNo(no => no + 1)
                }
                if (vote.response == "abstain") {
                    setAbstain(abstain => abstain + 1)
                }
            })
        } else if (props.vote) {
            setYes(props.vote.yes)
            setNo(props.vote.no)
            setAbstain(props.vote.abstain)
        }
    }, [props.voting, props.vote])

    return (
        <div className="flex rounded p-6 w-full">
            {props.voting ?
                <div className="flex-1">
                    <p className="text-md capitalize font-semibold">{props.voting.question}</p>
                </div> : <></>}
            {props.voting && props.voting.votes ?
                <div className="flex justify-end">
                    <div className="px-3 text-center">
                        <p>👍</p>
                        <p>{yes}</p>
                    </div>
                    <div className="px-3 text-center">
                        <p>🤷‍♂️</p>
                        <p>{abstain}</p>
                    </div>
                    <div className="px-3 text-center">
                        <p>👎</p>
                        <p>{no}</p>
                    </div>
                </div>
                : props.vote ?
                    <div className="flex justify-end">
                        <div className="px-3 text-center">
                            <ArrowUpCircleIcon className="mb-2 cursor-pointer text-lime-500" onClick={() => {props.addVote(1)}} />
                            <p>👍</p>
                            <p>{yes}</p>
                            <ArrowDownCircleIcon className="mt-2 cursor-pointer text-red-500" onClick={() => {props.subVote(1)}} />
                        </div>
                        <div className="px-3 text-center">
                            <ArrowUpCircleIcon className="mb-2 cursor-pointer text-lime-500"  onClick={() => {props.addVote(2)}} />
                            <p>🤷‍♂️</p>
                            <p>{abstain}</p>
                            <ArrowDownCircleIcon className="mt-2 cursor-pointer text-red-500"  onClick={() => {props.subVote(2)}} />
                        </div>
                        <div className="px-3 text-center">
                            <ArrowUpCircleIcon className="mb-2 cursor-pointer text-lime-500"  onClick={() => {props.addVote(3)}} />
                            <p>👎</p>
                            <p>{no}</p>
                            <ArrowDownCircleIcon className="mt-2 cursor-pointer text-red-500" onClick={() => {props.subVote(3)}} />
                        </div>
                    </div>
                    : <p className="font-semibold">Stemming bezig</p>
            }
        </div>
    )
}