import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

// import components
import VideoComponent from "./ui/video.component";

import AssembloLogo from "../assets/img/assemblo-logo.svg";
import WingUpkot from "../assets/img/waiting/wing.png";
import Socials from "../assets/img/waiting/socials.png";
import Facebook from "../assets/img/waiting/facebook.svg";
import Instagram from "../assets/img/waiting/instagram.svg";
import LinkedIn from "../assets/img/waiting/linkedin.svg";
import YouTube from "../assets/img/waiting/youtube.svg";

// import services
import televisionService from "../services/television.service";
import { toast } from "react-toastify";
import Spinner from "./ui/spinner.component";

export default function WaitingComponent(props) {
    const { t } = useTranslation();
    const [videos, setVideos] = useState([]);
    const [timeUntilMeeting, setTimeUntilMeeting] = useState(getTimeUntilMeeting(props.date));
    const [videoView, setVideoView] = useState();
    const [video, setVideo] = useState(false);

    useEffect(() => {
        televisionService.getAll()
            .then((response) => {
                const newVideos = response.data.map((element) => {
                    let title;
                    if (props.lang.toLowerCase() === "nl") {
                        title = element.title_nl;
                    } else if (props.lang.toLowerCase() === "fr") {
                        title = element.title_fr;
                    } else {
                        title = element.title_en;
                    }

                    return {
                        name: title,
                        uri: element.uri,
                    };
                });

                setVideos(newVideos);
            })
            .catch((error) => {
                toast.error("Er is iets fout gegaan bij het ophalen van de wachttelevisie.");
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeUntilMeeting(getTimeUntilMeeting(props.date));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [props.date]);

    return (
        <div class="dark-no-bg" style={{ paddingBottom: "0" }}>
            <div className="flex h-screen">
                <div className="w-1/3 center mt-auto mb-auto">
                <div>
                    <img 
                        src={AssembloLogo} 
                        alt="Assemblo logo"
                        width={200} 
                        height={200}
                        className="mx-auto"
                    />
                    <div>
                    <div className="text-white text-[40px] font-bold">{t("meeting.start.patience")}</div>
                    <div className="text-white text-2xl font-light">{t("meeting.start.soon")}</div>
                    <div className="text-white text-2xl font-bold mt-6">
                    {timeUntilMeeting.days != 0 &&
                        <>
                        {timeUntilMeeting.days} {timeUntilMeeting.days > 1 || timeUntilMeeting.days == 0 ? t("meeting.start.days") : t("meeting.start.day")}{' '}{t("meeting.start.and")}
                        </>}
                    </div>
                    <div className="text-white text-[80px] font-bold -mt-2">
                    {timeUntilMeeting.hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                    :
                    {timeUntilMeeting.minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                    :
                    {timeUntilMeeting.seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                    </div>
                    </div>
                </div>
                </div>
                <div className="w-2/3 mx-20 mt-auto mb-auto">
                    <div className="bg-white rounded-3xl w-4/5 flex max-h-content">
                        <div className="m-5 flex-col w-content w-1/2">
                            <img
                                src={WingUpkot}
                                alt="Wing Upkot"
                                width={400}
                                height={400}
                                className="rounded-3xl w-full w-max-1/3 h-full object-cover transition-transform"
                            />
                        </div>
                        <div className="flex-col text-left mt-auto mb-auto p-5 w-1/2">
                            <p className="text-[#321548] text-base font-normal mb-1">{t("meeting.start.advertising.1.title")}</p>
                            <h3 className="text-[#321548] text-4xl font-bold">{t("meeting.start.advertising.1.project")}</h3>
                            <p className="text-[#321548] text-base font-normal mt-2">{t("meeting.start.advertising.1.subtitle")}
                            <b>{" "}{t("meeting.start.advertising.1.fat")}</b>
                            </p>
                            <button
                                className="h-12 px-8 py-3.5 bg-fuchsia-800 rounded-2xl justify-center items-center gap-2.5 inline-flex mt-6"
                                onClick={() => { 
                                    window.open("https://upgrade-estate.be/nl/investeren/wing-upkot", "_blank");
                                 }}
                            >
                                <span 
                                className="text-white text-base font-bold">
                                    {t("meeting.start.advertising.1.button")}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl w-4/5 flex max-h-content ml-auto mt-10">
                        <div className="m-5 flex-col w-content w-1/2">
                            <img
                                src={Socials}
                                alt="Upgrade Estate"
                                width={400}
                                height={400}
                                className="rounded-3xl w-full w-max-1/3 h-full object-cover transition-transform"
                            />
                        </div>
                        <div className="flex-col text-left mt-auto mb-auto p-5 w-1/2">
                            <p className="text-[#321548] text-base font-normal mb-1">{t("meeting.start.advertising.2.title")}</p>
                            <h3 className="text-[#321548] text-4xl font-bold">{t("meeting.start.advertising.2.project")}</h3>
                            <div className="mt-8">
                                <img 
                                    src={Facebook}
                                    alt="Facebook"
                                    width={40}
                                    height={40}
                                    className="inline-block hover:cursor-pointer ml-4"
                                    onClick={() => {
                                        window.open("https://www.facebook.com/upgradeestate/", "_blank");
                                    }}
                                />
                                <img 
                                    src={Instagram}
                                    alt="Instagram"
                                    width={40}
                                    height={40}
                                    className="inline-block hover:cursor-pointer ml-4"
                                    onClick={() => {
                                        window.open("https://www.instagram.com/upgradeestate/", "_blank");
                                    }}
                                />
                                <img 
                                    src={LinkedIn}
                                    alt="LinkedIn"
                                    width={40}
                                    height={40}
                                    className="inline-block hover:cursor-pointer ml-4"
                                    onClick={() => {
                                        window.open("https://linkedin.com/company/upgrade-estate", "_blank");
                                    }}
                                />
                                <img 
                                    src={YouTube}
                                    alt="YouTube"
                                    width={40}
                                    height={40}
                                    className="inline-block hover:cursor-pointer ml-4"
                                    onClick={() => {
                                        window.open("https://www.youtube.com/channel/UCyvSsxnYmGCBKcVUXG6u2mA", "_blank");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getTimeUntilMeeting(meetingDatetime) {
    const currentTime = new Date();
    const meetingTime = new Date(meetingDatetime);
    const timeDifference = meetingTime - currentTime;

    const secondsUntilMeeting = Math.floor(timeDifference / 1000);
    const minutesUntilMeeting = Math.floor(secondsUntilMeeting / 60);
    const hoursUntilMeeting = Math.floor(minutesUntilMeeting / 60);
    const daysUntilMeeting = Math.floor(hoursUntilMeeting / 24);

    return {
        days: daysUntilMeeting > 0 ? daysUntilMeeting : 0,
        hours: hoursUntilMeeting % 24 > 0 ? hoursUntilMeeting % 24 : 0,
        minutes: minutesUntilMeeting % 60 > 0 ? minutesUntilMeeting % 60 : 0,
        seconds: secondsUntilMeeting % 60 > 0 ? secondsUntilMeeting % 60 : 0,
    };
}