import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import YouTube from 'react-youtube';

export default function VideoComponent({ uri, open, setOpen }) {
    const [dimensions, setDimensions] = useState(getDimensions());
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (open == true) {
            if (uri) {
                if (uri.includes("http")) {
                    window.open(uri, '_blank');
                    setOpen(false);
                } else {
                    setModalOpen(true);
                }
            }
        }
    }, [open, uri]);

    useEffect(() => {
        if (modalOpen == false) {
            setOpen(false);
        }
    }, [modalOpen]);
    

    function getDimensions() {
        const width = 0.5 * window.innerWidth;
        const height = width * 0.5625;
        return {
            width: width,
            height: height
        }
    }
      
    return (
        <Transition.Root show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setModalOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-5 text-left shadow-xl transition-all w-auto">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center">
                                        <div className="mt-4 z-50">
                                            <YouTube
                                                videoId={uri}
                                                opts={{
                                                    height: dimensions.height,
                                                    width: dimensions.width,
                                                    playerVars: {
                                                        autoplay: 1,
                                                        controls: 0,
                                                        disablekb: 1,
                                                        fs: 0,
                                                        modestbranding: 1,
                                                        rel: 0,
                                                        showinfo: 0,
                                                        iv_load_policy: 3,
                                                        playsinline: 1,
                                                        origin: window.location.origin
                                                    }
                                                }}
                                            
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}