import api from "./api/api";
import TokenService from "./api/token";

class ReviewService {
  get(id) {
    return api.get("/av/review/get/" + id + "/");
  }

  createReview(id, review) {
    return api.post("/av/review/create/", {
      "meeting_id": id,
      "rating": review
    });
  }

  addComment(id, comment) {
    return api.post("/av/review/comment/", {
      "meeting_id": id,
      "comment": comment
    });
  }
}

export default new ReviewService();