import api from "./api/api";
import TokenService from "./api/token";

class ChatService {
  getChats(id) {
    return api.get("/av/chat/list/" + id + "/");
  }

  getChatsAdmin(id) {
    return api.get("/av/chat/list/admin/" + id + "/");
  }
}

export default new ChatService();