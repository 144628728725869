import { useRecoilState } from "recoil";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

//import services
import AuthService from "../services/auth.service";

//import datastores
import { loggedInState } from "../data/loggedIn.atom";

export default function Footer({ limited, mail }) {
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    const { t, i18n } = useTranslation();

    function logout() {
        AuthService.logout();
        setLoggedIn(false);
    }

    function switchLang(lang) {
        if (limited) {
            i18n.changeLanguage(lang);
            return;
        } else {
            AuthService.switchLang(lang)
                .then(() => {
                    i18n.changeLanguage(lang);
                })
                .catch(() => {
                    toast.error("Er is iets misgelopen bij het wisselen van taal. Probeer het later opnieuw.");
                })
        }
    }

    return (
        <>
            {!limited ? (
                <>
                    <div className="flex text-xs items-center justify-center">
                        {/* green dot */}
                        <svg className="-ml-1 mr-1.5 h-2.5 w-2.5 text-green-500" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                        </svg>
                        <p className="mr-5">
                            {t("footer.help")}:
                        </p>
                        {/* 
                            temporarely removed
                            
                        <p className="underline mx-1.5 text-center cursor-pointer">
                            David Gistelinck <br />(<a href="tel:+32498663543">+32 498 66 35 43</a>)
                        </p>
                        - */}
                        <p className="underline mx-1.5 text-center cursor-pointer">
                            Yentl Prieels (<a href="tel:+32494258376">+32 494 25 83 76</a>)
                        </p>
                        -
                        <p className="underline mx-1.5 text-center cursor-pointer">
                            Louis Mylle (<a href="tel:+32472714637">+32 472 71 46 37</a>)
                        </p>

                    </div>
                    {mail ?
                        <div className="mt-5">
                            {t("footer.mail")}: {mail}
                        </div>
                        : null}
                </>
            ) : null}
            <div
                className="px-4 py-6 sm:px-0 bg-white rounded-2xl shadow-md mt-16 text-center"
            >
                {!limited ? (
                    <a
                        onClick={() => logout()}
                        className="underline px-2 cursor-pointer"
                    >{t("footer.logout")}</a>
                ) : null}

                {i18n.resolvedLanguage != "nl" ? (
                    <a
                        onClick={() => switchLang("nl")}
                        className="underline px-2 cursor-pointer"
                    >
                        NL
                    </a>
                ) : null}

                {i18n.resolvedLanguage != "fr" ? (
                    <a
                        onClick={() => switchLang("fr")}
                        className="underline px-2 cursor-pointer"
                    >
                        FR
                    </a>
                ) : null}

                {i18n.resolvedLanguage != "en" ? (
                    <a
                        onClick={() => switchLang("en")}
                        className="underline px-2 cursor-pointer"
                    >
                        EN
                    </a>
                ) : null}

                <a
                    href="https://upgrade-estate.be/nl/algemene-voorwaarden"
                    className="underline px-2 cursor-pointer"
                >
                    {t("footer.terms")}
                </a>

                <a
                    href="https://upgrade-estate.be/nl/privacybeleid/"
                    className="underline px-2 cursor-pointer"
                >
                    {t("footer.privacy")}
                </a>


                {!limited ? (
                    <Link
                        to="/help/"
                        className="underline px-2 cursor-pointer"
                    >
                        Help
                    </Link>
                ) : null}
            </div>
        </>
    )
}