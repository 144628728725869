import api from "./api/api";
import TokenService from "./api/token";

class MeetingService {
  getMeetings() {
    return api.get("/av/meeting/list/");
  }

  status(id) {
    return api.post("/av/meeting/status/", { meeting_id: id });
  }

  joinMeeting(id) {
    return api.post("/av/meeting/join/", { meeting_id: id })
      .then(response => {
        if (response.data) {
          TokenService.setLiveToken(response.data.token);
        }
        return response.data;
      });
  }

  getInvitees(id) {
    return api.get("/av/attend/list/" + id + "/");
  }

  getMeeting(id) {
    return api.get("/av/meeting/get/" + id + "/");
  }

  updateRSVP(id, rsvp) {
    return api.post("/av/attend/rvsp/",
      {
        meeting_id: id,
        rsvp: rsvp
      }
    );
  }
}

export default new MeetingService();