import api from "./api/api";
import TokenService from "./api/token";

class MeetingAdminService {
  status(id, status) {
    return api.post("/av/socket/status/", {
      'meeting_id': id,
      'status': status
    });
  }

  getAllMeetings() {
    return api.get("/av/meeting/list/admin/");
  }

  getAllUsers() {
    return api.get("/admin/users/list/");
  }

  inviteUser(meeting_id, user_id) {
    return api.post("/av/attend/invite/", {
      'meeting_id': meeting_id,
      'user_id': user_id
    });
  }

  deleteUserInvite(id) {
    return api.delete("/av/attend/delete/" + id + "/");
  }

  createMeeting(name, start_date, start_time, building) {
    return api.post("/av/meeting/create/", {
      'name': name,
      'date': start_date,
      'time': start_time,
      'building': building
    });
  }

  getMeeting(id) {
    return api.get("/av/meeting/admin/get/" + id + "/");
  }

  deleteMeeting(id) {
    return api.delete("/av/meeting/delete/" + id + "/");
  }

  updateMeeting(id, start_date, start_time) {
    return api.put("/av/meeting/update/", {
      'id': id,
      'date': start_date,
      'time': start_time
    });
  }

  clearRedis() {
    return api.get("/av/socket/redis/remove/");
  }

  refreshUser(id) {
    return api.post("/av/socket/refreshuser/",
      {
        user_id: id
      })
  }

  exportData(id, data) {
    return api.get("/export/" + data + "/" + id + "/", {
      responseType: 'blob'
    });
  }

  getMeetingStats(id) {
    return api.get("/av/socket/statistics/" + id + "/");
  }

  updateRSVP(meeting_id, user_id, rsvp) {
    return api.post("/av/attend/rsvpadmin/",
      {
        meeting_id: meeting_id,
        user_id: user_id,
        rsvp: rsvp
      }
    );
  }
}

export default new MeetingAdminService();