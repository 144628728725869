import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Director, View, Publish } from '@millicast/sdk';
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useRecoilState } from 'recoil';

//import datastores
import { liveState } from '../../../data/live.atom';

//import services
import meetingAdminService from '../../../services/meeting.admin.service';

const inputStreamOptions = [
    { id: 1, title: 'Presentator', description: 'Gebruik OBS-WebRTC om presentatie en videos te streamen.' },
    { id: 2, title: 'Camera & NL', description: 'Stream de camera en Nederlandse audio vanuit je browser.' },
    { id: 3, title: 'FR (enkel audio)', description: 'Stream de Franse audio vanuit je brower.' },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function AdminLivestreamView() {
    const mainPlayer = useRef();
    const subPlayer = useRef();

    const { id } = useParams();

    const [meeting, setMeeting] = useState({});
    const [meetingRefresh, setMeetingRefresh] = useState(0);

    const [mediaDevices, setMediaDevices] = useState([]);

    const [audioDevice, setAudioDevice] = useState();
    const [videoDevice, setVideoDevice] = useState();

    const [inputStream, setInputStream] = useState({ id: 4 });

    const [broadcast, setBroadcast] = useState(false);

    const [live, setLive] = useRecoilState(liveState);

    //use effect to load meeting
    useEffect(() => {
        meetingAdminService.getMeeting(id)
            .then(response => {
                setMeeting(response.data);
                console.log(response.data)
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });
    }, []);


    //use effect to load main and sub player
    useEffect(() => {
        if (meeting.title && meeting.accounttoken) {
            const tokenGenerator = () =>
                Director.getSubscriber({
                    streamName: meeting.title,
                    streamAccountId: meeting.accounttoken,
                    unauthorizedSubscribe: true,
                });

            const millicastMainPlayer = new View(meeting.title, tokenGenerator);
            const millicastSubPlayer = new View(meeting.title, tokenGenerator);

            millicastMainPlayer.on("track", (event) => {
                mainPlayer.current.srcObject = event.streams[0];
                mainPlayer.current.play();
            });

            millicastSubPlayer.on("track", (event) => {
                subPlayer.current.srcObject = event.streams[0];
                subPlayer.current.play();
            });

            try {
                millicastMainPlayer.connect({
                    pinnedSourceId: "mainPlayer",
                });
            } catch (e) {
                console.log("Connection failed, handle error", e);
                toast.error("Failed to connect to mainplayer. This is critical.")
                millicastMainPlayer.reconnect();
            }

            try {
                millicastSubPlayer.connect({
                    pinnedSourceId: "subPlayer",
                });
            } catch (e) {
                console.log("Connection failed, handle error", e);
                toast.error("Failed to connect to subplayer. This is critical.")
                millicastSubPlayer.reconnect();
            }
        } else {
            console.log("No stream or account");
        }
    }, [meeting, meetingRefresh]);

    //use effect to load media devices and stream them
    useEffect(() => {
        async function broadcast() {
            if (inputStream.id == 2) {
                const tokenGenerator = () =>
                    Director.getPublisher({
                        token: meeting.publishtoken,
                        streamName: meeting.title,
                    });

                const publisher = new Publish(meeting.title, tokenGenerator);
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: audioDevice,
                    },
                    video: {
                        deviceId: videoDevice,
                    },
                });
                const broadcastOptions = {
                    mediaStream: mediaStream,
                    sourceId: "subPlayer",
                };

                try {
                    await publisher.connect(broadcastOptions);
                    setLive(id);
                    setMeetingRefresh(meetingRefresh + 1);
                } catch (e) {
                    console.log("Connection failed, handle error" + e);
                }
            } else if (inputStream.id == 3) {
                const tokenGenerator = () =>
                    Director.getPublisher({
                        token: meeting.publishtoken,
                        streamName: meeting.title,
                    });

                const publisher = new Publish(meeting.title, tokenGenerator);
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: audioDevice,
                    },
                });
                const broadcastOptions = {
                    mediaStream: mediaStream,
                    sourceId: "frAudio",
                };

                try {
                    await publisher.connect(broadcastOptions);
                    setLive(id);
                    setMeetingRefresh(meetingRefresh + 1);
                } catch (e) {
                    console.log("Connection failed, handle error" + e);
                }
            }
        }
        if (broadcast) {
            broadcast();
        }
    }, [broadcast]);

    function stopStream() {
        //stop stream
    }

    useEffect(() => {
        async function getDevices() {
            await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            navigator.mediaDevices
                .enumerateDevices()
                .then((result) => {
                    setMediaDevices(result);
                })
                .catch(() => {
                    alert("We are unable to define your input devices...");
                });
        }

        if (inputStream.id == 2 || inputStream.id == 3) {
            getDevices();
        }

    }, [inputStream]);

    return (
        <main className="flex h-screen">
            <div className="py-6 w-2/3">
                <div className="flex mx-auto px-4 sm:px-6 md:px-8">
                    <div>
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Livestream</h1>
                        </div>
                        <div>
                            <p>Beheer de livestreams. Selecteer de inputs. </p>
                        </div>
                    </div>
                </div>

                <div className="px-6">
                    <RadioGroup value={inputStream} onChange={setInputStream}>
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                            {inputStreamOptions.map((inputStream) => (
                                <RadioGroup.Option
                                    key={inputStream.id}
                                    value={inputStream}
                                    className={({ checked, active }) =>
                                        classNames(
                                            checked ? 'border-transparent' : 'border-gray-300',
                                            active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                            'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                        )
                                    }
                                >
                                    {({ checked, active }) => (
                                        <>
                                            <span className="flex flex-1">
                                                <span className="flex flex-col">
                                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                        {inputStream.title}
                                                    </RadioGroup.Label>
                                                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                        {inputStream.description}
                                                    </RadioGroup.Description>
                                                </span>
                                            </span>
                                            <CheckCircleIcon
                                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    active ? 'border' : 'border-2',
                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-lg'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>


                    {inputStream.id == 1 ?
                        <div className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <p className="text-sm font-medium text-gray-900">Connectiegegevens hoofdspeler (presentator).</p>
                            <p className="text-sm text-gray-700 mb-10">Neem onderstaande gegevens integraal over in OBS-WebRTC, start de stream en klik op "ik ben aan het streamen".</p>
                            <div>
                                <div className="mt-5 border-t border-gray-200">
                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                            <dt className="text-sm font-medium text-gray-500">Stream Name</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{meeting.title}</dd>
                                        </div>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                            <dt className="text-sm font-medium text-gray-500">Publishing Token</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{meeting.publishtoken}</dd>
                                        </div>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                            <dt className="text-sm font-medium text-gray-500">Codec</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">h264</dd>
                                        </div>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                            <dt className="text-sm font-medium text-gray-500">sourceId</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">mainPlayer</dd>
                                        </div>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                            <dt className="text-sm font-medium text-gray-500">Publish API URL (onder advanced)</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">https://director.millicast.com/api/director/publish</dd>
                                        </div>
                                    </dl>
                                </div>
                                <a
                                    onClick={() => setMeetingRefresh(meetingRefresh + 1)}
                                    className="mt-5 inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                                >
                                    IK BEN AAN HET STREAMEN
                                </a>
                            </div>
                        </div>

                        : inputStream.id == 2 ?
                            <div className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <p className="text-sm font-medium text-gray-900 mb-3">Camera en nederlandse audio.</p>
                                <div>
                                    <select
                                        id="nlcam"
                                        name="nlcam"
                                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        onChange={(e) => { setVideoDevice(e.target.value) }}
                                    >
                                        {mediaDevices ?
                                            //filter mediadevices on audioinput
                                            mediaDevices.map((device, index) => {
                                                return (
                                                    device.kind == "videoinput" ?
                                                        <option key={index} value={device.deviceId}>{device.label}</option>
                                                        : <></>
                                                )
                                            }) : null}
                                    </select>
                                </div>
                                <div>
                                    <select
                                        id="nlaudio"
                                        name="nlaudio"
                                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        onChange={(e) => { setAudioDevice(e.target.value) }}
                                    >
                                        {mediaDevices ?
                                            //filter mediadevices on audioinput
                                            mediaDevices.map((device, index) => {
                                                return (
                                                    device.kind == "audioinput" ?
                                                        <option key={index} value={device.deviceId}>{device.label}</option>
                                                        : <></>
                                                )
                                            }) : null}
                                    </select>
                                </div>
                                <div className="mt-5 ">
                                    {live > 0 ?
                                        <a
                                            onClick={() => window.location.reload()}
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                        >
                                            STOP STREAM
                                        </a>
                                        :
                                        <a
                                            onClick={() => setBroadcast(true)}
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                                        >
                                            START STREAM
                                        </a>
                                    }
                                </div>
                            </div>

                            : inputStream.id == 3 ?
                                <div className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <p className="text-sm font-medium text-gray-900 mb-3">Franse (getolkte) audio.</p>
                                    <div>
                                        <select
                                            id="fraudio"
                                            name="fraudio"
                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            onChange={(e) => { setAudioDevice(e.target.value) }}
                                        >
                                            {mediaDevices ?
                                                mediaDevices.map((device, index) => {
                                                    return (
                                                        device.kind == "audioinput" ?
                                                            <option key={index} value={device.deviceId}>{device.label}</option>
                                                            : <></>
                                                    )
                                                }) : null}
                                        </select>
                                    </div>
                                    <div className="mt-5">
                                        {live > 0 ?
                                            <a
                                                onClick={() => window.location.reload()}
                                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                            >
                                                STOP STREAM
                                            </a>
                                            :
                                            <a
                                                onClick={() => setBroadcast(true)}
                                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                                            >
                                                START STREAM
                                            </a>
                                        }
                                    </div>
                                </div>

                                : <></>
                    }

                </div>


                <p className="mt-20">
                </p>
                <p className='mt-10'>
                    {/* {JSON.stringify(meeting)} */}
                </p>
            </div>
            <div className="py-6 w-1/3 bg-gray-100 h-max-screen">
                <div className="flex mx-auto px-4 sm:px-6 md:px-8">
                    <div>
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Voorbeelden</h1>
                        </div>
                        <div>
                            <p>Hieronder zie je de 2 voorbeeldstreams. Dit is exact hoe de gebruiker ze te zien krijgt.</p>
                        </div>
                    </div>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8 pt-10">
                    <h2 className='text-xl font-semibold text-gray-700'>Hoofdspeler:</h2>
                    <video controls className="w-full pt-3" ref={mainPlayer}></video>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8 pt-12">
                    <h2 className='text-xl font-semibold text-gray-700'>Subspeler:</h2>
                    <video controls className="w-full pt-3" ref={subPlayer} muted></video>
                </div>
            </div>
        </main>
    )
}