import api from "./api/api";
import TokenService from "./api/token";

class TelevisionService {
  getAll() {
    return api.get("/television/list/");
  }

  update(data) {
    return api.post("/television/update/", { data: data });
  }

}

export default new TelevisionService();