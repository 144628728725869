import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { saveAs } from 'file-saver';

//import services
import meetingAdminService from '../../../services/meeting.admin.service';
import { toast } from 'react-toastify';


export default function AdminSettingsView() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [date, setDate] = useState("");
    const [time, setTime] = useState("");

    useEffect(() => {
        meetingAdminService.getMeeting(id)
            .then(response => {
                setTime(Moment(response.data.date).format("HH:mm"));
                setDate(Moment(response.data.date).format("YYYY-MM-DD"));
            })
            .catch(err => {
                console.log(err);
            }
            );
    }, []);

    function updateDateTime() {
        meetingAdminService.updateMeeting(id, date, time)
            .then(response => {
                setTime(Moment(response.data.date).format("HH:mm"));
                setDate(Moment(response.data.date).format("YYYY-MM-DD"));
                toast.success("Datum en tijd zijn succesvol aangepast.");
            }
            )
            .catch(err => {
                toast.error(err.response.data.message);
            }
            );
    }

    function deleteAV() {
        let confirm = window.confirm("Weet je zeker dat je deze vergadering wilt verwijderen? Dit kan niet ongedaan worden gemaakt.");
        if (confirm) {
            meetingAdminService.deleteMeeting(id)
                .then(response => {
                    navigate("/admin/meetings/");
                }
                )
                .catch(err => {
                    toast.error(err.response.data.message);
                });
        }
    }
    function exportData(data) {
        meetingAdminService.exportData(id, data)
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                const date = new Date().toISOString().slice(0, 10);
                saveAs(blob, data + "-export_" + id + "_" + date + ".xlsx");
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.message);
            });
    }
    function clearRedis() {
        var input = prompt("Are you sure you want to clear redis? This will clear all active meetings and users. Type 'yes' to continue.");
        input = input.toLowerCase();
        if (input != "yes") {
            return;
        } else {
            meetingAdminService.clearRedis()
                .then(response => {
                    toast.success("Redis has been cleared. All active meetings and users have been removed.");
                })
                .catch(err => {
                    toast.error("Error clearing redis, please escalate this to the developers.");
                });
        }
    }


    return (
        <main className="flex-1">
            <div className="py-6">
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <div className="mb-10">
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Instellingen</h1>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Exporteer chatgeschiedenis</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Exporteer de volledige chatgeschiedenis van deze online vergadering. Hierin worden alle berichten, namen en tijdstippen opgenomen.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                    <button
                                        onClick={() => { exportData("chat") }}
                                        type="button"
                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg mt-5">
                        <div className="px-4 py-5 sm:p-6">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Exporteer stemgeschiedenis</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Exporteer de volledige stemgeschiedenis van deze online vergadering. Hierin worden alle stemmen, namen en tijdstippen opgenomen.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                    <button
                                        onClick={() => { exportData("voting") }}
                                        type="button"
                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg mt-5">
                        <div className="px-4 py-5 sm:p-6">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Exporteer reviews</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Exporteer de volledige reviewlijst van deze online vergadering. Hierin worden alle reviews, namen en tijdstippen opgenomen.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                    <button
                                        onClick={() => { exportData("review") }}
                                        type="button"
                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg mt-5">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Wijzig datum</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>Wijzig de datum waarop deze online vergadering plaats zal vinden.</p>
                            </div>
                            <form className="mt-5 sm:flex sm:items-center">
                                <div className="w-full sm:max-w-xs">
                                    <label htmlFor="email" className="sr-only">
                                        Date
                                    </label>
                                    <input
                                        type="date"
                                        name="date"
                                        id="date"
                                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={date}
                                        onChange={e => setDate(e.target.value)}
                                    />
                                </div>
                                <a
                                    onClick={() => updateDateTime()}
                                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Wijzigen
                                </a>
                            </form>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg mt-5">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Wijzig tijdstip</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>Wijzig het tijdstip waarop deze online vergadering zal plaats vinden.</p>
                            </div>
                            <form className="mt-5 sm:flex sm:items-center">
                                <div className="w-full sm:max-w-xs">
                                    <label htmlFor="email" className="sr-only">
                                        Time
                                    </label>
                                    <input
                                        type="time"
                                        name="time"
                                        id="time"
                                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={time}
                                        onChange={e => setTime(e.target.value)}
                                    />
                                </div>
                                <a
                                    onClick={() => updateDateTime()}
                                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Wijzigen
                                </a>
                            </form>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg mt-5">
                        <div className="px-4 py-5 sm:p-6">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Verwijder de AV</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Verwijder deze online vergadering. Dit kan niet ongedaan worden gemaakt.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                    <button
                                        onClick={() => deleteAV()}
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                    >
                                        AV verwijderen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow-md sm:rounded-lg mt-5">
                        <div className="px-4 py-5 sm:p-6">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Developer opties</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Maak de Redis cache-database leeg. Dit kan niet ongedaan worden gemaakt en is alleen voor developers.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                    <button
                                        onClick={() => clearRedis()}
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                    >
                                        REDIS WISSEN
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}