import { ArrowRightCircleIcon } from "@heroicons/react/24/outline"
import { useRef, useEffect } from "react"

export default function ChatComponent(props) {
    const chatBoxRef = useRef(null)

    useEffect(() => {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }, [props.chat, props.paddingTop]);


    return (
        <div className="h-full">
            <div className="overflow-x-none overflow-y-scroll scrolling-touch scrollbar-hide"
                style={
                    {
                        height: `calc(100vh - ${props.paddingTop}px - 3rem - ${props.hideInput != true ? 3 : 0}rem)`
                    }
                } ref={chatBoxRef}>
                {props.chat ?
                    props.chat.map((message, index) => (
                        <div key={index} className="my-2">
                            <div className="bg-white rounded-md shadow-lg p-2">
                                <p className="text-sm text-gray-600">{message.user}</p>
                                <p>{message.message}</p>
                            </div>
                        </div>
                    ))
                    : <></>}
            </div>

            {props.hideInput != true ?
            <div className="relative mt-2 rounded-md shadow-sm">
                <input
                    type="text"
                    name="message"
                    id="message"
                    className="block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={props.message}
                    onChange={e => props.setMessage(e.target.value)}
                    onKeyDown={e => e.key == "Enter" ? props.sendNewChat() : null}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ArrowRightCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={(e) => props.sendNewChat()} />
                </div>
            </div>
            : <></>}
        </div >
    )
}