//import assets
import { ReactComponent as AssembloLogo } from "../assets/img/assemblo-logo.svg";
import Nele from "../assets/img/Nele.png";
import LegendMeetingStatus from "./legend-meeting-status.component";
import BackgroundBlocks from "../assets/img/bg-blocks.svg";
import { useTranslation } from "react-i18next";
import VideoComponent from "./ui/video.component";
import { useState } from "react";


export default function HeaderComponent({
    text,
    subtext,
    name,
    explainer,
    showMeetingLegend = false,
}) {

    const { t } = useTranslation();
    const [showVideo, setShowVideo] = useState(false);

    return (
        <div className="">
            <header
                className="relative pt-4 bg-purple-900 text-white py-48 mb-48 text-center "
                style={{
                    minHeight: "200px",
                    backgroundImage: "url(" + BackgroundBlocks + ")",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    background: ` center center no-repeat, radial-gradient(circle, rgb(57, 43, 95), rgb(50, 21, 72));`,
                }}
            >
                <div className="text-sm mx-auto">
                    <div className="flex flex-col">
                        <AssembloLogo
                            className="mx-auto w-28"
                            onClick={() => { window.location.href = "/" }}
                        />
                        <div className="max-w-sm mx-auto">
                            <p className="text-3xl font-semibold my-2">
                                {text + " "}
                                {name !== "" ? (
                                    <span className="text-red-600">{name}</span>
                                ) : (
                                    <></>
                                )}
                            </p>
                            {subtext ? <p className="text-sm">{subtext}</p> : <></>}
                        </div>
                        {explainer ? (
                            <div className="relative mt-8 h-36 w-80 mx-auto">
                                <div className="flex items-center h-full justify-center">
                                    <div className="mr-24 z-10">
                                        <p className="text-xl font-extralight">
                                            <i>
                                                "{t("home.header.why1")}
                                                <br /> {t("home.header.why2")}"
                                            </i>
                                        </p>
                                        <button
                                            className="button-primary"
                                            onClick={() => setShowVideo(true)}
                                        >
                                            {t("home.header.play")}
                                        </button>
                                    </div>

                                    <img
                                        src={Nele}
                                        className="w-56 z-0  absolute -top-4 -right-12"
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {showMeetingLegend && (
                            <>
                                <p className="text-lg">
                                    {t("home.header.upcoming")}
                                </p>
                                <div className="mt-4 -mb-8">
                                    <LegendMeetingStatus darkMode />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <p></p>
                <div className="hero-border-left-bottom">
                    <div className="hero-border-left-bottom-inner"></div>
                </div>
                <div className="hero-border-right-bottom">
                    <div className="hero-border-right-bottom-inner"></div>
                </div>
            </header>
            <VideoComponent open={showVideo} setOpen={setShowVideo} uri={"mTBSx1U0jlM"} />
        </div>
    );
}
