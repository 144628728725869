import api from "./api/api";
import TokenService from "./api/token";

class BuildingService {
  getBuildings() {
    return api.get("/building/list/");
  }

  uploadFile(formData) {
    return api.post("/file/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  createBuilding(data) {
    return api.post("/building/create/", data);
  }

  getContracts() {
    return api.get("/building/contracts/");
  }

}

export default new BuildingService();