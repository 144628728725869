import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { useEffect, useLayoutEffect } from 'react'
import { useRecoilState } from 'recoil';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18next';


//import services
import AuthService from './services/auth.service';
import { SocketContext, socket } from './services/socket.service';

//import datastores
import { loggedInState } from './data/loggedIn.atom';
import { liveState } from './data/live.atom';
import { meState } from './data/me.atom';

//import views
import AuthView from './views/auth/auth.view';
import TokenView from './views/auth/token.view';
import HomeView from './views/home.view';
import MeetingView from './views/meeting.view';
import HelpView from './views/help.view';

//toplevel admin menu
import AdminHomeView from './views/admin/adminhome.view';
import ManageMeeting from './views/admin/managemeeting.view';
import AdminMenu from './views/admin/menu.view';
import AdminUsersView from './views/admin/users.view';
import AdminMeetingsView from './views/admin/meetings.view';
import AdminBuildingsView from './views/admin/buildings.view';
import AdminMeetingView from './views/admin/meeting.view';
import AdminTelevisionView from './views/admin/television.view';

//sub admin 
import AdminMeetingUsersView from './views/admin/meeting/users.view';
import AdminLivestreamView from './views/admin/meeting/livestream.view';
import AdminSettingsView from './views/admin/meeting/settings.view';

//import components (only when necessary)
import AdminTabMenu from './components/admin/tabmenu.component';

function App() {
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const [me, setMe] = useRecoilState(meState);
  const [live, setLive] = useRecoilState(liveState);


  useLayoutEffect(() => {
    if (localStorage.getItem("user")) {
      AuthService.check()
        .then(response => {
          setLoggedIn(true);
        })
        .catch(err => {
          setLoggedIn(false);
        });
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      AuthService.me()
        .then(response => {
          setMe(response.data);
          if (response.data.lang) {
            i18n.changeLanguage(response.data.lang);
            console.log("Language changed to " + response.data.lang);
          }
        })
        .catch(err => {
          toast.error("Er is iets misgegaan bij het ophalen van je gegevens. Probeer het later opnieuw.")
        });
    }
  }, [loggedIn]);


  return (
    <>
      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          {live > 0 ?
            <div className="fixed z-30 inset-x-0 bottom-0 pb-2 sm:pb-5">
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="rounded-lg bg-red-600 p-2 shadow-lg sm:p-3">
                  <div className="flex flex-wrap items-center justify-between">
                    <div className="flex w-0 flex-1 items-center">
                      <span className="flex rounded-lg bg-red-800 p-2">
                        <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                      <p className="ml-3 truncate font-medium text-white">
                        <span className="md:hidden"><span className="font-bold">Je bent aan het streamen!</span> Refresh dit browservenster niet, je kunt Assemblo wel nog gebruiken.</span>
                        <span className="hidden md:inline"><span className="font-bold">Je bent aan het streamen!</span> Refresh dit browservenster niet, je kunt Assemblo wel nog gebruiken.</span>
                      </p>
                    </div>
                    <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                      <Link
                        to={"/admin/meeting/" + live + "/livestream"}
                        className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-indigo-50"
                      >
                        Naar livestream
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> : <></>}
          <Routes>
            {loggedIn == false
              ? <>
                <Route path="/" element={<AuthView />} />
                <Route path="*" element={<PageNotFound html={`<h1 class="text-3xl">You are guest. Page not found!</h1>`} />} />
              </>
              : <>
                <Route path="/" element={<HomeView />} />
                <Route path="/meeting/:id" element={<MeetingView />} />
                <Route path="/help/" element={<HelpView />} />
                <Route path="*" element={<PageNotFound html={`<h1 class="text-3xl">You are logged in as user. Page not found!</h1>`} />} />
                {me.admin
                  ? <>
                    <Route path="/admin/" element={<AdminMenu />}>
                      <Route path="" element={<AdminHomeView />} />
                      <Route path="users" element={<AdminUsersView />} />
                      <Route path="buildings" element={<AdminBuildingsView />} />
                      <Route path="meetings" element={<AdminMeetingsView />} />
                      <Route path="meeting/:id" element={<AdminTabMenu />}>
                        <Route path="" element={<ManageMeeting />} />
                        <Route path="livestream" element={<AdminLivestreamView />} />
                        <Route path="invitees" element={<AdminMeetingUsersView />} />
                        <Route path="settings" element={<AdminSettingsView />} />
                      </Route>
                      <Route path="television" element={<AdminTelevisionView />} />
                    </Route>
                    <Route path="preview/:id" element={<AdminMeetingView />} />
                    <Route path="*" element={<PageNotFound html={`<h1 class="text-3xl">You are logged in as admin. Page not found!</h1>`} />} />
                  </>
                  : <></>}
              </>}
            <Route path="/auth/:token" element={<TokenView />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </SocketContext.Provider>
    </>
  );
}

const PageNotFound = ({ html }) => {
  return (
    <div className="dark flex flex-col justify-center items-center" style={{ paddingBottom: "0" }} dangerouslySetInnerHTML={{ __html: html }}>
    </div>
  );
};
export default App;
